-<template>
  <div class="top-user-card-conent card shodow-sm">
    <div class="card-body">
      <div class="card-badge">Top {{ type }}</div>
      <div class="card-block">
        <div class="user-block">
          <div class="user-image">
            <img src="/media/users/user1.jpg" alt="user image" />
          </div>
          <div class="user-name">
            <div class="first-name">{{ firstName }}</div>
            <div class="last-name">{{ lastName }}</div>
          </div>
        </div>
        <div class="info-block">
          <span class="info-heading">{{ infoText.text }}</span>
          <div class="info">
            <div class="left-block">
              <span
                class="dollar-sign"
                v-if="infoText.text == 'Revenue' && region === 'PAKISTAN'"
                >PKR</span
              >
              <span class="dollar-sign" v-if="infoText.text == 'Revenue'"
                >$</span
              >
              <span v-if="infoText.text === 'Revenue' && region === 'US'">{{
                infoText.value
              }}</span>
              <span class="ratings-block" v-if="infoText.text === 'Rating'">
                <i
                  v-for="index in 5"
                  class="fa fa-star"
                  v-bind:class="getGoldClass(infoText.ratingValue, index)"
                  :key="index"
                ></i>
              </span>
              <span v-if="infoText.text === 'Rating'" style="margin-left:2.5px;"
                >{{ infoText.ratingValue }}.0</span
              >
            </div>
            <div class="right-block" v-bind:class="getTrendColor">
              <i
                class="fa fa-arrow-down"
                v-if="infoText.trend === 'negative'"
              ></i>
              <i
                class="fa fa-arrow-up"
                v-if="infoText.trend === 'positive'"
              ></i>
              {{ infoText.trendValue }}%
            </div>
          </div>
        </div>
      </div>
      <div class="card-block">
        <div v-if="region === 'PAKISTAN'" class="dollar-value-text">
          PKR {{ value }}
        </div>
        <div v-if="region === 'US'" class="dollar-value-text">${{ value }}</div>
        <div class="info-block">
          <span class="info-heading">Processed Orders</span>
          <div class="info">
            <div class="left-block">{{ infoText.processedOrders }}</div>
            <div
              class="right-block"
              v-bind:class="getProcessedOrdersTrendColor"
            >
              <i
                class="fa fa-arrow-down"
                v-if="infoText.processedOrdersTrend === 'negative'"
              ></i>
              <i
                class="fa fa-arrow-up"
                v-if="infoText.processedOrdersTrend === 'positive'"
              ></i>
              {{ infoText.processedOrdersTrendValue }}%
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "TopUserCard",
  props: {
    type: String,
    value: Number,
    firstName: String,
    lastName: String,
    infoText: Object
  },

  computed: {
    getTrendColor: function() {
      return this.infoText.trend == "positive" ? "green-text" : "red-text";
    },
    getProcessedOrdersTrendColor: function() {
      return this.infoText.processedOrdersTrend == "positive"
        ? "green-text"
        : "red-text";
    },
    ...mapState({
      region: state => state.region.isRegion
    })
  },
  methods: {
    getGoldClass: function(total, key) {
      return key <= total ? "gold" : "";
    }
  }
};
</script>

<style lang="scss">
.top-user-card-conent {
  width: 100%;
  min-height: 192px;
  height: 100%;
  .card-body {
    padding: 15px;
    padding-top: 30px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 100%;
    .card-block {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;
      padding: 0 5px;
    }
    .card-badge {
      position: absolute;
      left: 0;
      top: 0;
      background: rgb(251, 156, 23);
      background: linear-gradient(
        90deg,
        rgba(251, 156, 23, 1) 0%,
        rgba(255, 201, 125, 1) 100%
      );
      padding: 2.5px;
      color: #fff;
    }
    .dollar-value-text {
      font-size: 24px;
      text-align: center;
    }
    .user-block {
      display: flex;
      width: 100%;
      padding: 10px 0;
      .user-image {
        width: 25%;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          height: 45px;
          width: 45px;
          border-radius: 50%;
        }
      }
      .user-name {
        margin-left: 10px;
        width: 100%;
        display: flex;
        flex-direction: column;
        .first-name {
          font-size: 14px;
        }
        .last-name {
          font-size: 14px;
        }
      }
    }
    .info-block {
      padding: 5px 0;
      display: flex;
      flex-direction: column;
      .info-heading {
        font-size: 12px;
        text-transform: uppercase;
        color: #ddd;
        padding: 5px 0;
      }
      .info {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        .left-block {
          font-weight: 700;
          .ratings-block {
            i.gold {
              color: #ff9500;
            }
          }
        }
        .right-block {
          &.green-text {
            color: #4dca65;
            i {
              color: #4dca65;
            }
          }
          &.red-text {
            color: #e31716;
            i {
              color: #e31716;
            }
          }
          font-size: 12px;
          i {
            margin-right: 2.5px;
          }
        }
        .dollar-sign {
          margin-right: 5px;
        }
      }
    }
  }
}
.modal-open .modal {
  display: flex !important;
  align-items: center;
}
</style>
