var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('table',{staticClass:"status-graph table table-borderless"},[_c('tr',{staticClass:"status-graph__row"},[_c('td',[_c('span',{staticClass:"status-graph__circle",class:_vm.order.status === 'Pending'
              ? (_vm.circleClass + "--blink")
              : (_vm.circleClass + "--done")})]),_c('td',{staticClass:"status-graph__status"},[_vm._v("Accepted")]),_c('td',[_vm._v(" "+_vm._s(_vm.order.status === "Pending" ? "Pending" : _vm.$moment(_vm.order.timeAccepted).format("h:mm a"))+" ")]),_c('td')]),_vm._l((_vm.order.stores),function(store){return [_c('tr',{key:store.store,staticClass:"status-graph__row"},[_c('td',[_c('span',{staticClass:"status-graph__circle",class:store.status === 'Picked' || store.status === 'Reached'
                ? (_vm.circleClass + "--done")
                : (_vm.circleClass + "--pending")})]),_c('td',{staticClass:"status-graph__status"},[_vm._v(_vm._s(store.name)+"[Reach]")]),_c('td',[_vm._v(" "+_vm._s(_vm.$moment(store.timeReached).format("h:mm a"))+" ")]),_c('td',[_vm._v(" "+_vm._s(store.status === "Reached" || store.status === "Picked" ? ("Reached in " + (_vm.$humanize(store.durationReached * 1000, { units: ["m"], round: true }))) : ("Expected to reach in " + (_vm.$humanize( store.durationExpected * 1000, { units: ["m"], round: true } ))))+" ")])]),_c('tr',{key:store.store,staticClass:"status-graph__row"},[_c('td',[_c('span',{staticClass:"status-graph__circle",class:store.status === 'Reached'
                ? (_vm.circleClass + "--blink")
                : store.status === 'Picked'
                ? (_vm.circleClass + "--done")
                : (_vm.circleClass + "--pending")})]),_c('td',{staticClass:"status-graph__status"},[_vm._v(_vm._s(store.name)+"[Picked]")]),_c('td',[_vm._v(" "+_vm._s(store.status === "Picked" ? _vm.$moment(store.timePicked).format("h:mm a") : "Pending")+" ")]),_c('td',[_vm._v(" "+_vm._s(store.status === "Picked" ? ("Picked in " + (_vm.$humanize(store.durationShopped * 1000, { units: ["m"], round: true }))) : ("Expected to pick in " + (_vm.$humanize( store.durationExpected * 1000, { units: ["m"], round: true } ))))+" ")])])]}),_c('tr',{staticClass:"status-graph__row"},[_c('td',[_c('span',{staticClass:"status-graph__circle",class:_vm.order.status === 'Delivered'
              ? (_vm.circleClass + "--done")
              : _vm.order.status === 'Reached Customer'
              ? (_vm.circleClass + "--blink")
              : (_vm.circleClass + "--pending")})]),_c('td',{staticClass:"status-graph__status"},[_vm._v(_vm._s(_vm.order.customer.name))]),_c('td',[_vm._v(" "+_vm._s(_vm.order.status === "Delivered" || _vm.order.status === "Reached Customer" ? _vm.$moment(_vm.order.timeReachedCustomer).format("h:mm a") : "Pending")+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.order.status === "Delivered" || _vm.order.status === "Reached Customer" ? ("Reached in " + (_vm.$humanize(_vm.order.durationReachedCustomer * 1000, { units: ["m"], round: true }))) : ("Expected to reach in " + (_vm.$humanize( _vm.order.durationExpected * 1000, { units: ["m"], round: true } ))))+" ")])]),_c('tr',{staticClass:"status-graph__row"},[_c('td',[_c('span',{staticClass:"status-graph__circle",class:_vm.order.status === 'Delivered'
              ? (_vm.circleClass + "--done")
              : (_vm.circleClass + "--pending")})]),_c('td',{staticClass:"status-graph__status"},[_vm._v("Delivered")]),_c('td',[_vm._v(" "+_vm._s(_vm.order.status === "Delivered" ? _vm.$moment(_vm.order.timeDelivered).format("h:mm a") : "Pending")+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.order.status === "Delivered" ? ("Delivered in " + (_vm.$humanize(_vm.order.durationDelivered * 1000, { units: ["m"], round: true }))) : "Pending")+" ")])])],2)])}
var staticRenderFns = []

export { render, staticRenderFns }